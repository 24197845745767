import { store } from "@app/store"
import {
  Configuration,
  CourseDto,
  CourseItemValidation,
  CourseDescriptorDto,
  CourseProvisionsApi,
  CoursesApi,
  UpdateCourseRequest,
} from "@masterschool/course-builder-api"
import { selectAccessToken } from "@features/login/loginSelectors"

export type ClientResponse<S, F> =
  | {
      kind: "success"
      value: S
    }
  | {
      kind: "failure"
      value: F
    }

export class CourseClient {
  private static courseClient = new CoursesApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  private static courseProvisionsClient = new CourseProvisionsApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  private static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static createCourse(): Promise<CourseDto> {
    return CourseClient.courseClient
      .courseControllerCreate(CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static listCourseDescriptors(): Promise<CourseDescriptorDto[]> {
    return CourseClient.courseClient
      .courseControllerListDescriptors(CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static createCourseDraft(id: string): Promise<CourseDto> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerDraft(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static duplicateCourse(
    courseId: string,
    version: number,
  ): Promise<CourseDto> {
    return CourseClient.courseClient
      .courseControllerDuplicate(
        { courseId, version },
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static listCourses(): Promise<CourseDto[]> {
    return CourseClient.courseClient
      .courseControllerList(CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static getCourse(id: string): Promise<CourseDto> {
    return CourseClient.courseClient
      .courseControllerGet(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static getCourseByVersion(id: string, version: number): Promise<CourseDto> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerGet(id, version, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static getLastPublishedVersion(id: string): Promise<CourseDto | undefined> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerGetPublishedCourseVersion(
        id,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data.course
      })
  }

  static updateCourse(
    request: UpdateCourseRequest,
    id: string,
  ): Promise<CourseDto> {
    return CourseClient.courseClient
      .courseControllerSave(request, id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static removeCourse(id: string): Promise<void> {
    return CourseClient.courseClient
      .courseControllerRemove(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static discardDraft(id: string): Promise<void> {
    return CourseClient.courseClient
      .courseControllerDiscardDraft(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static publishCourse(
    id: string,
  ): Promise<ClientResponse<CourseDto, CourseItemValidation>> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerPublish(id, CourseClient.makeHeaders())
      .then((response) => {
        return {
          kind: "success" as const,
          value: response.data,
        }
      })
      .catch((e) => {
        const validations = e.response.data.message
          .errors as CourseItemValidation
        if (
          e.response.data.message.message === "Invalid publish course request"
        ) {
          return {
            kind: "failure" as const,
            value: validations,
          }
        }

        throw e
      })
  }

  static archiveCourse(id: string): Promise<CourseDto> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerArchive(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static listCourseVersions(id: string): Promise<CourseDescriptorDto[]> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerList(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static deleteCourse(id: string): Promise<void> {
    return CourseClient.courseClient
      .courseControllerRemove(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static getValidations(id: string): Promise<CourseItemValidation> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerGetPublishValidations(
        id,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static revertToVersion(id: string, version: number): Promise<CourseDto> {
    return CourseClient.getCourseByVersion(id, version).then((course) => {
      if (!course) {
        throw new Error("Course not found")
      }

      return CourseClient.updateCourse(
        {
          ...course,
          status: undefined,
        },
        id,
      )
    })
  }
}
