import { useAppDispatch, useAppSelector } from "@app/hooks"
import {
  discardChangesRequested,
  discardDraftRequested,
  validateCourseForPreview,
} from "@features/courseEditor/courseEditorSlice"
import {
  deleteCourse,
  duplicateCourse,
} from "@features/coursesMenu/coursesMenuSlice"
import { selectDependentSyllabuses } from "@features/coursesMenu/coursesSelectors"
import { PopupState, PopupType, popupItemClicked } from "@features/ui/uiSlice"
import {
  CourseDescriptorDto,
  CourseStatus,
} from "@masterschool/course-builder-api"
import { useNavigate } from "react-router-dom"
import { useEditCourseClicked } from "../../editor/custom-hooks/useEditCourseClicked"

function useCourseMenuActions(course: CourseDescriptorDto) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const syllabusDependencies = useAppSelector(
    selectDependentSyllabuses(course.id),
  )

  const { id: courseId, status, version } = course

  const onOpenClicked = () => {
    if (course.status === CourseStatus.Draft) {
      navigate(`/course-edit/${course.id}`)
    } else {
      navigate(`/course/${course.id}?version=${course.version}`)
    }
  }

  const onPreviewInCampusClicked = () => {
    const popupModel: PopupState = {
      course,
      type: PopupType.CampusCoursePreview,
    }
    switch (status) {
      case CourseStatus.Published:
        dispatch(popupItemClicked(popupModel))
        break
      case CourseStatus.Draft:
        dispatch(validateCourseForPreview({ courseId })).then(
          (flattenedValidations) => {
            const isCourseValid =
              Object.keys(flattenedValidations.payload ?? {}).length === 0
            if (isCourseValid) {
              dispatch(popupItemClicked(popupModel))
            }
          },
        )
        break
      case CourseStatus.Archived:
        throw new Error("Course is archived, cannot preview")
    }
  }

  const onEditClicked = useEditCourseClicked()

  const onDuplicateClicked = () => {
    dispatch(duplicateCourse({ courseId, version }))
  }

  const onArchiveClicked = () => {
    dispatch(
      popupItemClicked({
        courseId,
        type: PopupType.ArchiveCourseConfirmation,
      }),
    )
  }

  const onVersionHistoryClicked = () => {
    dispatch(
      popupItemClicked({
        courseId,
        type: PopupType.CourseVersionsHistory,
        context: "courseEditor",
      }),
    )
  }

  const onDependentSyllabusesClicked = () => {
    dispatch(
      popupItemClicked({
        syllabusDependencies,
        type: PopupType.DependentSyllabuses,
      }),
    )
  }

  const onDeleteClicked = () => {
    dispatch(deleteCourse(courseId))
  }

  const onDiscardChangesClicked = () => {
    dispatch(discardChangesRequested())
  }

  const onDiscardDraftClicked = () => {
    dispatch(discardDraftRequested())
  }

  return {
    onOpenClicked,
    onPreviewInCampusClicked,
    onEditClicked,
    onDuplicateClicked,
    onArchiveClicked,
    onVersionHistoryClicked,
    onDependentSyllabusesClicked,
    onDeleteClicked,
    onDiscardChangesClicked,
    onDiscardDraftClicked,
  }
}

export default useCourseMenuActions
