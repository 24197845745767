import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  editRolesPopupAccepted,
  editRolesPopupClosed,
  editRolesPopupTutorProperties,
  roleCheckboxToggled,
} from "@features/account-management/accountManagementSlice"
import { selectIsAdmin } from "@features/login/loginSelectors"
import { Role } from "@features/login/loginSlice"
import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import usePrevious from "@utils/hooks/usePrevious"
import appTheme from "../theme/appTheme"
import roleDisplayNames from "./roleDisplayName"
import { TutorDto } from "@clients/tutorClient"
import TutorFormFields from "./tutorFormFields"

function EditRolesDialog() {
  const model = useAppSelector(
    (state) => state.accountManagement.editRolesPopup,
  )
  const previousModel = usePrevious(model, {
    saveOnlyDefined: true,
  })
  const dispatch = useAppDispatch()
  const roleOptions = [
    Role.MasterschoolEmployee,
    Role.Instructor,
    Role.Mentor,
    Role.ProgramManager,
    Role.SchoolMasterAdmin,
    Role.CareerSuccessAdvisor,
    Role.ProfessionalMentor,
    Role.SalesTeamLead,
    Role.SalesAdmin,
    Role.AcademicSupportAdvisor,
    Role.Tutor,
  ]
  const isAdmin = useAppSelector(selectIsAdmin)
  if (isAdmin) {
    roleOptions.push(Role.AccountManager, Role.LmsAdmin)
  }
  const unselectedMentor =
    previousModel?.user.roles.includes(Role.Mentor) &&
    !model?.selectedRoles.includes(Role.Mentor)

  const isTutorSelected = model?.selectedRoles.includes(Role.Tutor)
  const isUserRoleUnchanged = areArraysEqual(
    model?.selectedRoles ?? [],
    model?.user.roles ?? [],
  )
  const isTutorPropertiesUnchanged = areTutorPropertiesEqual(
    model?.tutorProperties,
    previousModel?.tutorProperties,
  )

  const isSaveDisabled = isUserRoleUnchanged && isTutorPropertiesUnchanged

  const middleIndex = Math.ceil(roleOptions.length / 2)

  const leftColumnItems = roleOptions.slice(0, middleIndex)
  const rightColumnItems = roleOptions.slice(middleIndex)

  const roleCheckbox = (role: Role) => {
    return (
      <FormControlLabel
        checked={(model?.selectedRoles ?? []).includes(role)}
        key={role}
        control={<Checkbox />}
        label={roleDisplayNames(role)}
        onChange={() => {
          dispatch(roleCheckboxToggled({ role }))
        }}
      />
    )
  }

  return (
    <GenericDialog
      open={model !== undefined}
      onClose={() => dispatch(editRolesPopupClosed(undefined))}
      size="sm"
      title={`Edit ${(model ?? previousModel)?.user.firstName} ${
        (model ?? previousModel)?.user.lastName
      }'s roles`}
      content={
        <FormGroup>
          <Stack gap={3}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="column">
                {leftColumnItems.map((role) => roleCheckbox(role))}
              </Stack>
              <Stack direction="column">
                {rightColumnItems.map((role) => roleCheckbox(role))}
              </Stack>
            </Stack>
            {isTutorSelected && (
              <TutorFormFields
                tutorProperties={model?.tutorProperties}
                onChange={(tutorProperties) => {
                  dispatch(editRolesPopupTutorProperties({ tutorProperties }))
                }}
              />
            )}
          </Stack>
          {model?.requestStatus === "error" && (
            <Typography color={appTheme.palette.error.main}>
              Something went wrong, please refresh and try again
            </Typography>
          )}
          {unselectedMentor && <RemovingMentorRoleWarning />}
        </FormGroup>
      }
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: () => dispatch(editRolesPopupClosed(undefined)),
        },
        {
          text: "Save",
          type: "primary",
          onClick: () => dispatch(editRolesPopupAccepted()),
          loading: model?.requestStatus === "pending",
          disabled: isSaveDisabled,
        },
      ]}
    />
  )
}

function areArraysEqual<T>(a: T[], b: T[]): boolean {
  return a.length === b.length && a.every((v, i) => v === b[i])
}
function areTutorPropertiesEqual(
  a: TutorDto["properties"] | undefined,
  b: TutorDto["properties"] | undefined,
): boolean {
  if (a === b) return true
  if (a === undefined || b === undefined) return false

  return (
    areArraysEqual(a.domains, b.domains) &&
    areArraysEqual(a.languages, b.languages) &&
    areArraysEqual(a.supportedSubjects, b.supportedSubjects)
  )
}

const RemovingMentorRoleWarning = () => {
  return (
    <Alert
      icon={
        <SvgIcon
          component={appIcons.alertTriangle}
          inheritViewBox
          sx={{ fill: "none", width: 20, height: 20 }}
        />
      }
      severity="warning"
    >
      Removing the mentor role will unassign any students from this staff
      member.
    </Alert>
  )
}

export default EditRolesDialog
