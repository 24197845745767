import {
  CodioProject,
  CourseSyllabusElement,
  CourseSyllabusElementTypeEnum,
  CourseSyllabusLiveEvent,
  CourseraLesson,
  CourseraTask,
  DatacampLesson,
  DatacampTasksGroup,
  EmptyLesson,
  EmptyProject,
  MasterschoolLesson,
  MasterschoolTask,
  NotionLesson,
  OtherLesson,
  OtherLessonTask,
  RunestoneLesson,
  RunestoneTask,
  Survey,
  Test,
  TryHackmeLesson,
  TypeformLesson,
  UdacityLesson,
  UdacityTask,
  CourseSyllabusLiveEventSessionTypeEnum,
  CodioLesson,
} from "@masterschool/course-builder-api"

class SyllabusElementFactory {
  private constructor() {}

  static makeElement(
    type: CourseSyllabusElementTypeEnum,
  ): CourseSyllabusElement {
    switch (type) {
      case CourseSyllabusElementTypeEnum.DatacampLesson:
        return {
          type: CourseSyllabusElementTypeEnum.DatacampLesson,
          item: this.makeDatacampLesson(),
        }
      case CourseSyllabusElementTypeEnum.MasterschoolLesson:
        return {
          type: CourseSyllabusElementTypeEnum.MasterschoolLesson,
          item: this.makeMasterschoolLesson(),
        }
      case CourseSyllabusElementTypeEnum.NotionLesson:
        return {
          type: CourseSyllabusElementTypeEnum.NotionLesson,
          item: this.makeNotionLesson(),
        }
      case CourseSyllabusElementTypeEnum.OtherLesson:
        return {
          type: CourseSyllabusElementTypeEnum.OtherLesson,
          item: this.makeOtherLesson(),
        }
      case CourseSyllabusElementTypeEnum.CourseraLesson:
        return {
          type: CourseSyllabusElementTypeEnum.CourseraLesson,
          item: this.makeCourseraLesson(),
        }
      case CourseSyllabusElementTypeEnum.TryHackmeLesson:
        return {
          type: CourseSyllabusElementTypeEnum.TryHackmeLesson,
          item: this.makeTryHackmeLesson(),
        }
      case CourseSyllabusElementTypeEnum.TypeformLesson:
        return {
          type: CourseSyllabusElementTypeEnum.TypeformLesson,
          item: this.makeTypeformLesson(),
        }
      case CourseSyllabusElementTypeEnum.UdacityLesson:
        return {
          type: CourseSyllabusElementTypeEnum.UdacityLesson,
          item: this.makeUdacityLesson(),
        }
      case CourseSyllabusElementTypeEnum.CodioLesson:
        return {
          type: CourseSyllabusElementTypeEnum.CodioLesson,
          item: this.makeCodioLesson(),
        }
      case CourseSyllabusElementTypeEnum.RunestoneLesson:
        return {
          type: CourseSyllabusElementTypeEnum.RunestoneLesson,
          item: this.makeRunestoneLesson(),
        }
      case CourseSyllabusElementTypeEnum.CodioProject:
        return {
          type: CourseSyllabusElementTypeEnum.CodioProject,
          item: this.makeCodioProject(),
        }
      case CourseSyllabusElementTypeEnum.Test:
        return {
          type: CourseSyllabusElementTypeEnum.Test,
          item: this.makeTest(),
        }
      case CourseSyllabusElementTypeEnum.Survey:
        return {
          type: CourseSyllabusElementTypeEnum.Survey,
          item: this.makeSurvey(),
        }
      case CourseSyllabusElementTypeEnum.EmptyLesson:
        return {
          type: CourseSyllabusElementTypeEnum.EmptyLesson,
          item: this.makeEmptyLesson(),
        }
      case CourseSyllabusElementTypeEnum.EmptyProject:
        return {
          type: CourseSyllabusElementTypeEnum.EmptyProject,
          item: this.makeEmptyProject(),
        }
      default:
        throw new Error(`Cannot make element of type ${type}`)
    }
  }

  static makeLiveEvent(): CourseSyllabusLiveEvent {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      instructionUrl: "",
      materialsUrl: "",
      sessionType: CourseSyllabusLiveEventSessionTypeEnum.Lesson,
      description: "",
      isHidden: false,
    }
  }

  static makeDatacampLesson(): DatacampLesson {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      tasks: [],
      estimatedDuration: 0,
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeMasterschoolLesson(): MasterschoolLesson {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      tasks: [this.makeMasterschoolTask()],
      isHidden: false,
      isExtraCurricular: false,
      isMandatory: true,
      url: "",
    }
  }

  static makeNotionLesson(): NotionLesson {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      url: "",
      estimatedDuration: 0,
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeOtherLesson(): OtherLesson {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      url: "",
      estimatedDuration: 0,
      tasks: [this.makeOtherLessonTask()],
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeOtherLessonTask(url?: string): OtherLessonTask {
    return {
      id: window.crypto.randomUUID(),
      url: url ?? "",
    }
  }

  static makeCourseraLesson(): CourseraLesson {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      url: "",
      estimatedDuration: 0,
      tasks: [this.makeCourseraTask()],
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeCourseraTask(url?: string): CourseraTask {
    return {
      id: window.crypto.randomUUID(),
      url: url ?? "",
    }
  }

  static makeTryHackmeLesson(): TryHackmeLesson {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      url: "",
      estimatedDuration: 0,
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeTypeformLesson(): TypeformLesson {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      url: "",
      estimatedDuration: 0,
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeUdacityLesson(): UdacityLesson {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      url: "",
      estimatedDuration: 0,
      tasks: [],
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeUdacityTask(url?: string): UdacityTask {
    return {
      id: window.crypto.randomUUID(),
      url: url ?? "",
    }
  }

  static makeCodioLesson(): CodioLesson {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      url: "",
      estimatedDuration: 0,
      taskUrl: "",
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeRunestoneLesson(): RunestoneLesson {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      url: "",
      estimatedDuration: 0,
      tasks: [],
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeRunestoneTask(url?: string): RunestoneTask {
    return {
      id: window.crypto.randomUUID(),
      url: url ?? "",
    }
  }

  static makeCodioProject(): CodioProject {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      url: "",
      estimatedDuration: 0,
      taskUrl: "",
      solutionUrl: "",
      passingGrade: 0,
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeTest(): Test {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      url: "",
      estimatedDuration: 0,
      provider: "typeform",
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeMasterschoolTask(
    params?: Partial<Omit<MasterschoolTask, "id">>,
  ): MasterschoolTask {
    return {
      id: window.crypto.randomUUID(),
      title: params?.title ?? "",
      url: params?.url ?? "",
      estimatedDuration: 0,
    }
  }

  static makeDatacampTasksGroup(): DatacampTasksGroup {
    return {
      id: window.crypto.randomUUID(),
      url: "",
      exIds: [],
    }
  }

  static makeSurvey(): Survey {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      url: "",
      estimatedDuration: 0,
      provider: "",
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeEmptyLesson(): EmptyLesson {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      isExtraCurricular: false,
      isMandatory: true,
    }
  }

  static makeEmptyProject(): EmptyProject {
    return {
      id: window.crypto.randomUUID(),
      title: "",
      isHidden: false,
      isExtraCurricular: false,
      isMandatory: true,
    }
  }
}

export default SyllabusElementFactory
