import MsCalendar from "@masterschool/ui-calendar"

import { Stack } from "@mui/material"
import { useEventActions } from "../../main/calendar/useEventActions"
import { useGetCalendarById } from "../../main/calendar/useGetCalendar"
import {
  CalendarHubClient,
  MsPrivateCalendarDto,
} from "@clients/calendarHubClient"
import {
  CalendarGroupings,
  MsCalendarProps,
} from "@masterschool/ui-calendar/dist/src/types"
import { mapToMsCalendarProps } from "../../main/calendar/calendarMappers"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { useAppSelector } from "@app/hooks"
import { useEffect, useMemo, useState } from "react"
import { TutorClient, TutorDto } from "@clients/tutorClient"
import { domainToDisplayName } from "@utils/domainUtils"
import { FetchEventSnackbar } from "../../main/calendar/FetchEventSnackbar"

const d1 = new Date("2023-08-10T10:00:00.000Z")
const d2 = new Date("2027-08-20T10:00:00.000Z")

export const BookSessionWithTutor = () => {
  const user = useAppSelector(selectLoggedInUser)

  const dateRange = { start: d1, end: d2 }

  const tutors = useGetTutorList()
  const tutorCalendars = useGetTutorsCalendars()
  const tutorCalendarIds = useMemo(
    () => tutorCalendars.personalCalendars.map((c) => c.id),
    [tutorCalendars],
  )
  const studentCalendars = useGetStudentsCalendars()

  const { msEvents, onChange, onDelete, addCalendarIdsToFetch, loading } =
    useEventActions(tutorCalendarIds, dateRange)

  const tutorByUserClientId = useMemo(
    () =>
      tutors.reduce((acc, tutor) => {
        acc[tutor.userClientId] = tutor
        return acc
      }, {} as Record<string, TutorDto>),
    [tutors],
  )

  const getCalendarById = useGetCalendarById(msEvents, [
    ...tutorCalendars.personalCalendars,
    ...studentCalendars.personalCalendars,
  ])

  return (
    <Stack width={1} height={1} px={3} pt={8}>
      <Stack flex={1} overflow="auto">
        {loading && <FetchEventSnackbar />}
        <MsCalendar
          events={msEvents}
          calendars={buildCalendarGroupings(
            studentCalendars,
            tutorCalendars,
            tutorByUserClientId,
          )}
          getCalendar={getCalendarById}
          userId={user?.clientId || ""}
          onEventChange={onChange}
          supportCreateEventTypes={["tutoring-session"]}
          onDeleteEvent={onDelete}
          canJoinLiveSessions={false}
          onSelectedCalendarChange={(calendarIds) => {
            addCalendarIdsToFetch(calendarIds)
          }}
          customCalendarFilters={[
            {
              field: "language",
              label: "Language",
              type: "string",
              isMultiSelection: true,
              operators: ["equals"],
              isRequired: false,
            },
            {
              field: "domains",
              label: "Domains",
              type: "string",
              isMultiSelection: true,
              operators: ["equals"],
              isRequired: false,
            },
            {
              field: "subjects",
              label: "Subjects",
              type: "string",
              isMultiSelection: true,
              operators: ["equals"],
              isRequired: false,
            },
          ]}
        />
      </Stack>
    </Stack>
  )
}

const buildCalendarGroupings = (
  studentCalendars: {
    personalCalendars: MsPrivateCalendarDto[]
  },
  tutorCalendars: {
    personalCalendars: MsPrivateCalendarDto[]
  },
  tutorsMap: Record<string, TutorDto>,
): CalendarGroupings => {
  return [
    {
      title: "Tutors",
      type: "single",
      groupingsType: "staff",
      groupingsAction: "all",
      calendars: tutorCalendars.personalCalendars.map((c) =>
        mapToCalendarPropsForTutor(c, tutorsMap[c.userClientId]),
      ),
    },
    {
      title: "Students",
      type: "single",
      groupingsType: "students",
      groupingsAction: "all",
      calendars: studentCalendars.personalCalendars.map((c) =>
        mapToMsCalendarProps(c),
      ),
    },
  ]
}

const mapToCalendarPropsForTutor = (
  calendar: MsPrivateCalendarDto,
  tutor: TutorDto,
): MsCalendarProps => {
  const calendarProps = mapToMsCalendarProps(calendar)
  const tags = {
    language: tutor.properties.languages.map(languageWithFlag),
    subjects: tutor.properties.supportedSubjects,
    domains: tutor.properties.domains.map(domainToDisplayName),
  }
  return {
    ...calendarProps,
    tags,
  }
}

const useGetTutorsCalendars = () => {
  const [calendars, setCalendars] = useState<{
    personalCalendars: MsPrivateCalendarDto[]
  }>({
    personalCalendars: [],
  })
  const tutors = useGetTutorList()
  const tutorUserClientIds = useMemo(
    () => tutors.map((t) => t.userClientId),
    [tutors],
  )

  useEffect(() => {
    CalendarHubClient.getCalendarsByUserClientId(tutorUserClientIds).then(
      (res) => {
        setCalendars({ personalCalendars: res })
      },
    )
  }, [tutorUserClientIds])

  return calendars
}

const useGetTutorList = () => {
  const [tutors, setTutors] = useState<TutorDto[]>([])
  useEffect(() => {
    TutorClient.listTutors().then((res) => {
      setTutors(res)
    })
  }, [])
  return tutors
}

const useGetStudentsCalendars = () => {
  const studentClientIds = useGetEligibleStudents()
  const [calendars, setCalendars] = useState<{
    personalCalendars: MsPrivateCalendarDto[]
  }>({
    personalCalendars: [],
  })

  useEffect(() => {
    CalendarHubClient.getCalendarsByUserClientId(studentClientIds).then(
      (res) => {
        setCalendars({ personalCalendars: res })
      },
    )
  }, [studentClientIds])

  return calendars
}

const useGetEligibleStudents = () => {
  const [studentClientId, setStudentsClientId] = useState<string[]>([])
  useEffect(() => {
    TutorClient.listEligibleStudentsClientID().then((res) => {
      setStudentsClientId(res)
    })
  }, [])
  return studentClientId
}

function languageWithFlag(language: string) {
  switch (language) {
    case "English":
    case "english":
      return "English 🇬🇧"
    case "German":
    case "german":
      return "German 🇩🇪"
    default:
      return language
  }
}
