import {
  DatacampLesson,
  MasterschoolLesson,
  NotionLesson,
  OtherLesson,
  Survey,
  CourseSyllabusElementTypeEnum,
  Test,
  TryHackmeLesson,
  TypeformLesson,
  UdacityLesson,
  CourseraLesson,
  Topic,
  RunestoneLesson,
  CodioProject,
  EmptyLesson,
  EmptyProject,
  CodioLesson,
} from "@masterschool/course-builder-api"
import useEditElement from "./useEditElement"

export function useEditMasterschoolLesson() {
  const editElement = useEditElement()
  return <K extends keyof MasterschoolLesson>(
    lesson: MasterschoolLesson,
    key: keyof MasterschoolLesson,
    value: MasterschoolLesson[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.MasterschoolLesson,
        item: {
          ...lesson,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditDatacampLesson() {
  const editElement = useEditElement()
  return <K extends keyof DatacampLesson>(
    lesson: DatacampLesson,
    key: keyof DatacampLesson,
    value: DatacampLesson[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.DatacampLesson,
        item: {
          ...lesson,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditNotionLesson() {
  const editElement = useEditElement()
  return <K extends keyof NotionLesson>(
    lesson: NotionLesson,
    key: keyof NotionLesson,
    value: NotionLesson[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.NotionLesson,
        item: {
          ...lesson,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditEmptyLesson() {
  const editElement = useEditElement()
  return <K extends keyof EmptyLesson>(
    lesson: EmptyLesson,
    key: keyof EmptyLesson,
    value: EmptyLesson[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.EmptyLesson,
        item: {
          ...lesson,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditGenericLesson(
  type:
    | CourseSyllabusElementTypeEnum.OtherLesson
    | CourseSyllabusElementTypeEnum.CourseraLesson,
) {
  const editOtherLesson = useEditOtherLesson()
  const editCourseraLesson = useEditCourseraLesson()
  if (type === CourseSyllabusElementTypeEnum.OtherLesson) {
    return editOtherLesson
  }
  return editCourseraLesson
}

export function useEditOtherLesson() {
  const editElement = useEditElement()
  return <K extends keyof OtherLesson>(
    lesson: OtherLesson,
    key: keyof OtherLesson,
    value: OtherLesson[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.OtherLesson,
        item: {
          ...lesson,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditCourseraLesson() {
  const editElement = useEditElement()
  return <K extends keyof CourseraLesson>(
    lesson: CourseraLesson,
    key: keyof CourseraLesson,
    value: CourseraLesson[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.CourseraLesson,
        item: {
          ...lesson,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditRunestoneLesson() {
  const editElement = useEditElement()
  return <K extends keyof RunestoneLesson>(
    lesson: RunestoneLesson,
    key: keyof CourseraLesson,
    value: RunestoneLesson[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.RunestoneLesson,
        item: {
          ...lesson,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditTryHackmeLesson() {
  const editElement = useEditElement()
  return <K extends keyof TryHackmeLesson>(
    lesson: TryHackmeLesson,
    key: keyof TryHackmeLesson,
    value: TryHackmeLesson[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.TryHackmeLesson,
        item: {
          ...lesson,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditTypeformLesson() {
  const editElement = useEditElement()
  return <K extends keyof TypeformLesson>(
    lesson: TypeformLesson,
    key: keyof TypeformLesson,
    value: TypeformLesson[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.TypeformLesson,
        item: {
          ...lesson,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditUdacityLesson() {
  const editElement = useEditElement()
  return <K extends keyof UdacityLesson>(
    lesson: UdacityLesson,
    key: keyof UdacityLesson,
    value: UdacityLesson[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.UdacityLesson,
        item: {
          ...lesson,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditTest() {
  const editElement = useEditElement()
  return <K extends keyof Test>(
    test: Test,
    key: keyof Test,
    value: Test[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.Test,
        item: {
          ...test,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditSurvey() {
  const editElement = useEditElement()
  return <K extends keyof Survey>(
    survey: Survey,
    key: keyof Survey,
    value: Survey[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.Survey,
        item: {
          ...survey,
          [key]: value,
        },
      },
      topic,
    )
  }
}
export function useEditEmptyProject() {
  const editElement = useEditElement()
  return <K extends keyof EmptyProject>(
    lesson: EmptyProject,
    key: keyof EmptyProject,
    value: EmptyProject[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.EmptyProject,
        item: {
          ...lesson,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditCodioProject() {
  const editElement = useEditElement()
  return <K extends keyof CodioProject>(
    project: CodioProject,
    key: K,
    value: CodioProject[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.CodioProject,
        item: {
          ...project,
          [key]: value,
        },
      },
      topic,
    )
  }
}

export function useEditCodioLesson() {
  const editElement = useEditElement()
  return <K extends keyof CodioLesson>(
    project: CodioLesson,
    key: K,
    value: CodioLesson[K],
    topic: Topic,
  ) => {
    editElement(
      {
        type: CourseSyllabusElementTypeEnum.CodioLesson,
        item: {
          ...project,
          [key]: value,
        },
      },
      topic,
    )
  }
}
