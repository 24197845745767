import { RootState } from "@app/store"
import { filterProgramBySearchText } from "@features/program/programSliceSelectors"
import {
  CourseStatus,
  ProgramDomain,
  ProgramDto,
  ProgramType,
  SyllabusDto,
  SyllabusDtoStatusEnum,
} from "@masterschool/course-builder-api"
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import {
  filterSyllabuses,
  filterSyllabusesByTab,
} from "@utils/syllabus/syllabus-menu-helpers"
import { startDateToCohortStyleDate } from "@utils/syllabusTags"
import { filterCourses } from "../../main/main-page-helpers"
import {
  SortType,
  alphabeticCoursesSort,
  chronologicalCoursesSort,
} from "../../main/sort/coursesSortHelpers"
import {
  alphabeticSyllabusesSort,
  chronologicalSyllabusesSort,
} from "../../main/sort/syllabusSortHelpers"
import {
  selectCoursesMainPageTab,
  selectCoursesSortOption,
} from "../coursesMenu/coursesSelectors"
import {
  getLatestSyllabusVersion,
  selectLastVersionSyllabuses,
  selectSyllabuses,
  selectSyllabusesMainPageTab,
  selectSyllabusesSortOption,
} from "../syllabus/syllabusSelectors"

export interface SearchState {
  courseFilters: SearchComponentFilter
  syllabusFilters: SearchComponentFilter
  programFilters: SearchComponentFilter
}

export type SearchComponentFilter = {
  advanceFilters: SearchAdvanceFilter[]
  text: string
}

export type SearchAdvanceFilter = {
  filterName: FilterName
  optionalValues: string[]
  selectedValues: string[]
}

export enum FilterName {
  Domains = "Domains",
  Syllabuses = "Syllabuses",
  Cohort = "Cohort",
  Scope = "Scope",
  Role = "Role",
}

export const DomainsEnumeration = Object.values(ProgramDomain)

export const PROGRAM_FILTERS_KEY = "programFilters"
export const SYLLABUS_FILTERS_KEY = "syllabusFilters"
export const COURSE_FILTERS_KEY = "courseFilters"

const getInitialState: () => SearchState = () => {
  const storedProgramFilters = localStorage.getItem(PROGRAM_FILTERS_KEY)
  const programFilters = {
    advanceFilters: storedProgramFilters
      ? JSON.parse(storedProgramFilters)
      : [
          {
            filterName: FilterName.Scope,
            optionalValues: [ProgramType.FullTime, ProgramType.PartTime],
            selectedValues: [ProgramType.FullTime],
          },
        ],
    text: "",
  }
  const storedSyllabusFilters = localStorage.getItem(SYLLABUS_FILTERS_KEY)
  const storedCourseFilters = localStorage.getItem(COURSE_FILTERS_KEY)

  return {
    courseFilters: {
      advanceFilters: storedCourseFilters
        ? JSON.parse(storedCourseFilters)
        : [],
      text: "",
    },
    syllabusFilters: {
      advanceFilters: storedSyllabusFilters
        ? JSON.parse(storedSyllabusFilters)
        : [],
      text: "",
    },
    programFilters,
  }
}

export const searchSlice = createSlice({
  name: "search",
  initialState: getInitialState,
  reducers: {
    courseAdvanceFilterUpdated: (
      state,
      action: PayloadAction<SearchAdvanceFilter[]>,
    ) => {
      state.courseFilters.advanceFilters = action.payload
    },
    courseSearchTextUpdated: (state, action: PayloadAction<string>) => {
      state.courseFilters.text = action.payload
    },
    syllabusFilterUpdated: (
      state,
      action: PayloadAction<SearchAdvanceFilter[]>,
    ) => {
      state.syllabusFilters.advanceFilters = action.payload
    },
    syllabusSearchTextUpdated: (state, action: PayloadAction<string>) => {
      state.syllabusFilters.text = action.payload
    },
    programSearchTextUpdated: (state, action: PayloadAction<string>) => {
      state.programFilters.text = action.payload
    },
    programFilterUpdated: (
      state,
      action: PayloadAction<SearchAdvanceFilter[]>,
    ) => {
      state.programFilters.advanceFilters = action.payload
    },
  },
})

export const selectCourseFilters = (state: RootState) =>
  state.search.courseFilters

const selectPrograms = (state: RootState) => state.program.programs

export const selectCoursesSearch = createSelector(
  [selectCourseFilters, selectLastVersionSyllabuses],
  (courseFilters, syllabuses) => {
    return {
      advanceFilters: [
        {
          filterName: FilterName.Domains,
          optionalValues: DomainsEnumeration,
          selectedValues:
            courseFilters.advanceFilters.find(
              (cf) => cf.filterName === "Domains",
            )?.selectedValues || [],
        },
        {
          filterName: FilterName.Syllabuses,
          optionalValues: [...new Set(syllabuses.map((s) => s.name ?? ""))],
          selectedValues:
            courseFilters.advanceFilters.find(
              (cf) => cf.filterName === "Syllabuses",
            )?.selectedValues || [],
        },
      ],
      text: courseFilters.text,
    }
  },
)

const selectSyllabusFilters = (state: RootState) => state.search.syllabusFilters

export const selectSyllabusSearch = createSelector(
  [selectSyllabusFilters, selectPrograms],
  (syllabusFilters, programs) => {
    return {
      advanceFilters: [
        {
          filterName: FilterName.Domains,
          optionalValues: [...new Set(programs.map((p) => p.domain))],
          selectedValues:
            syllabusFilters.advanceFilters.find(
              (cf) => cf.filterName === "Domains",
            )?.selectedValues || [],
        },
        {
          filterName: FilterName.Cohort,
          optionalValues: programsToDescendingStartDates(programs),
          selectedValues:
            syllabusFilters.advanceFilters.find(
              (cf) => cf.filterName === "Cohort",
            )?.selectedValues || [],
        },
      ],
      text: syllabusFilters.text,
    }
  },
)

function programsToDescendingStartDates(programs: ProgramDto[]): string[] {
  return [
    ...new Set(
      programs
        .toSorted(
          (p1, p2) =>
            new Date(p2.startDate).getTime() - new Date(p1.startDate).getTime(),
        )
        .map((p) => startDateToCohortStyleDate(p.startDate.toString())),
    ),
  ]
}

const selectProgramFilters = (state: RootState) => {
  return state.search.programFilters
}

export const selectSearchedPrograms = createSelector(
  [selectProgramFilters, selectPrograms],
  (programFilters, programs) => {
    return {
      advanceFilters: [
        {
          filterName: FilterName.Domains,
          optionalValues: DomainsEnumeration,
          selectedValues:
            programFilters.advanceFilters.find(
              (pf) => pf.filterName === "Domains",
            )?.selectedValues || [],
        },
        {
          filterName: FilterName.Cohort,
          optionalValues: programsToDescendingStartDates(programs),
          selectedValues:
            programFilters.advanceFilters.find(
              (pf) => pf.filterName === "Cohort",
            )?.selectedValues || [],
        },
        {
          filterName: FilterName.Scope,
          optionalValues: ["full_time", "part_time"],
          selectedValues:
            programFilters.advanceFilters.find(
              (pf) => pf.filterName === "Scope",
            )?.selectedValues || [],
        },
      ],
      text: programFilters.text,
    }
  },
)

export const selectMainPagePrograms = createSelector(
  [selectPrograms, selectSearchedPrograms],
  (programs, filters) => {
    return filterProgramBySearchText(programs, filters.text).filter((p) =>
      filters.advanceFilters.every((f) => {
        switch (f.filterName) {
          case FilterName.Domains:
            return (
              f.selectedValues.length === 0 ||
              f.selectedValues.includes(p.domain)
            )
          case FilterName.Cohort:
            return (
              f.selectedValues.length === 0 ||
              f.selectedValues.includes(startDateToCohortStyleDate(p.startDate))
            )
          case FilterName.Syllabuses:
            return true
          case FilterName.Scope:
            return (
              f.selectedValues.length === 0 || f.selectedValues.includes(p.type)
            )
        }
        return true
      }),
    )
  },
)

const selectCourses = (state: RootState) => state.coursesMenu.courses

const selectFavoriteCourses = (state: RootState) =>
  state.coursesMenu.favoriteCourses

export const selectMainPageCourses = createSelector(
  [
    selectCourses,
    selectLastVersionSyllabuses,
    selectCourseFilters,
    selectCoursesMainPageTab,
    selectCoursesSortOption,
    selectFavoriteCourses,
  ],
  (courses, syllabuses, courseFilters, tab, sortOption, favoriteCourses) => {
    if (courses === "pending" || courses === "rejected") {
      return
    }
    const syllabusIdsToFilter = getSyllabusIdsByFromCohortTag(
      syllabuses,
      courseFilters.advanceFilters,
    )

    const allCoursesBesideDraftsWithHighVersion = courses?.filter(
      (course) =>
        (course.status === CourseStatus.Draft && course.version === 1) ||
        course.status !== CourseStatus.Draft,
    )

    const advanceFilter = getAdvanceFilterWithSyllabusIdsAsSelectedValues(
      courseFilters.advanceFilters,
      syllabusIdsToFilter!,
    )

    const filtered = filterCourses(
      allCoursesBesideDraftsWithHighVersion,
      syllabuses,
      courseFilters.text,
      advanceFilter,
      tab,
      favoriteCourses,
    )

    switch (sortOption.type) {
      case SortType.Alphabetic:
        alphabeticCoursesSort(filtered, sortOption.order)
        break
      case SortType.Chronological:
        chronologicalCoursesSort(filtered, sortOption.order)
        break
    }

    return filtered
  },
)

function getSyllabusIdsByFromCohortTag(
  syllabuses: SyllabusDto[],
  filters: SearchAdvanceFilter[],
): string[] {
  const courseSyllabusFilters = filters.find(
    (af) => af.filterName === FilterName.Syllabuses,
  )?.selectedValues

  return courseSyllabusFilters?.map(
    (filteredSyllabusName) =>
      syllabuses.find((s) => s.name === filteredSyllabusName)?.id ?? "",
  )!
}

function getAdvanceFilterWithSyllabusIdsAsSelectedValues(
  filters: SearchAdvanceFilter[],
  newSelectedValue: string[],
) {
  return filters.map((filter) => {
    if (filter.filterName === FilterName.Syllabuses) {
      return { ...filter, selectedValues: newSelectedValue }
    } else return filter
  })
}

export const selectMainPageSyllabuses = createSelector(
  [
    selectSyllabuses,
    selectSyllabusesSortOption,
    selectSyllabusSearch,
    selectSyllabusesMainPageTab,
  ],
  (syllabuses, sortOption, filters, tab) => {
    if (syllabuses.length === 0) {
      return syllabuses
    }

    const latestVersions = getLatestSyllabusVersion(syllabuses)

    let filteredSyllabuses = filterSyllabuses(
      latestVersions,
      filters.text,
      filters.advanceFilters,
    )
    const archivedSyllabuses = filteredSyllabuses.filter(
      (syllabus) => syllabus.status === SyllabusDtoStatusEnum.Archived,
    )
    filteredSyllabuses = [
      ...filteredSyllabuses.filter(
        (syllabus) => syllabus.status !== SyllabusDtoStatusEnum.Archived,
      ),
      ...archivedSyllabuses,
    ]

    filteredSyllabuses = filterSyllabusesByTab(filteredSyllabuses, tab)

    switch (sortOption.type) {
      case SortType.Alphabetic:
        alphabeticSyllabusesSort(filteredSyllabuses, sortOption.order)
        break
      case SortType.Chronological:
        chronologicalSyllabusesSort(filteredSyllabuses, sortOption.order)
        break
    }
    return filteredSyllabuses
  },
)

export const {
  courseAdvanceFilterUpdated,
  syllabusFilterUpdated,
  courseSearchTextUpdated,
  syllabusSearchTextUpdated,
  programSearchTextUpdated,
  programFilterUpdated,
} = searchSlice.actions

export default searchSlice.reducer
