import { useAppSelector } from "@app/hooks"
import OptionsButton from "@cmp/buttons/optionsButton"
import {
  CourseDescriptorDto,
  CourseStatus,
} from "@masterschool/course-builder-api"
import { SxProps, Theme } from "@mui/material"
import appIcons from "@utils/appIcons"
import useCourseMenuActions from "./useCourseMenuActions"
import { selectDependentSyllabuses } from "@features/coursesMenu/coursesSelectors"

function CourseDescriptorMenu(props: {
  course: CourseDescriptorDto
  sx?: SxProps<Theme>
  iconSx?: SxProps<Theme>
}) {
  const {
    onOpenClicked,
    onPreviewInCampusClicked,
    onEditClicked,
    onDuplicateClicked,
    onArchiveClicked,
    onVersionHistoryClicked,
    onDependentSyllabusesClicked,
    onDeleteClicked,
  } = useCourseMenuActions(props.course)

  const syllabusDependencies = useAppSelector(
    selectDependentSyllabuses(props.course.id),
  )
  const disableArchive = syllabusDependencies.length > 0

  const publishedItems = [
    {
      text: "Open",
      onSelect: onOpenClicked,
    },
    {
      text: "Preview in campus",
      onSelect: onPreviewInCampusClicked,
    },
    {
      text: "Edit",
      onSelect: () => onEditClicked(props.course.id, props.course.status),
    },
    {
      text: "Duplicate",
      onSelect: onDuplicateClicked,
    },
    {
      text: "Archive",
      onSelect: onArchiveClicked,
      disabled: disableArchive,
    },
    {
      text: "View version history",
      onSelect: onVersionHistoryClicked,
    },
    {
      text: "Show dependent syllabuses",
      onSelect: onDependentSyllabusesClicked,
    },
  ]

  const draftItems = [
    {
      text: "Open",
      onSelect: onOpenClicked,
    },
    {
      text: "Preview in campus",
      onSelect: onPreviewInCampusClicked,
    },
    {
      text: "Duplicate",
      onSelect: onDuplicateClicked,
    },
    {
      text: "Archive",
      onSelect: onArchiveClicked,
    },
  ]

  const archivedItems = [
    {
      text: "Preview",
      onSelect: onPreviewInCampusClicked,
    },
    {
      text: "Delete",
      onSelect: onDeleteClicked,
    },
  ]

  const items = {
    [CourseStatus.Published]: publishedItems,
    [CourseStatus.Draft]: draftItems,
    [CourseStatus.Archived]: archivedItems,
  }[props.course.status]

  return (
    <OptionsButton
      button={{
        sx: { padding: "2px" },
        leftIcon: appIcons.dots,
        iconSx: props.iconSx,
      }}
      items={items}
      sx={props.sx}
    />
  )
}

export default CourseDescriptorMenu
