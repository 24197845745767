import { useAppSelector } from "@app/hooks"
import { ProgramManagementCalendar } from "@clients/calendarHubClient"
import { selectShowCalendarFeature } from "@features/login/featureFlagSelectors"
import { selectPrograms } from "@features/program/programSliceSelectors"
import MsCalendar from "@masterschool/ui-calendar"
import { Stack } from "@mui/material"
import { useMemo, useState } from "react"
import { buildMsCalendarGroupingsForProgramOwner } from "./calendarMappers"
import { useEventActions } from "./useEventActions"
import {
  useGetCalendarById,
  useGetCalendarsForProgramOwner,
} from "./useGetCalendar"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { FetchEventSnackbar } from "./FetchEventSnackbar"

const d1 = new Date("2023-08-10T10:00:00.000Z")
const d2 = new Date("2027-08-20T10:00:00.000Z")

type DateRange = {
  start: Date
  end: Date
}

const CalendarContainer = () => {
  const isCalendarFeatureEnabled = useAppSelector(selectShowCalendarFeature)

  const calendarsForMenu = useGetCalendarsForProgramOwner()
  const [dateRange] = useState<DateRange>({ start: d1, end: d2 })
  if (!isCalendarFeatureEnabled) {
    return null
  }
  return (
    <CalendarWithEvent
      calendarsForMenu={calendarsForMenu}
      dateRange={dateRange}
    />
  )
}

const CalendarWithEvent = ({
  calendarsForMenu,
  dateRange,
}: {
  calendarsForMenu: ProgramManagementCalendar
  dateRange: DateRange
}) => {
  const programs = useAppSelector(selectPrograms)
  const user = useAppSelector(selectLoggedInUser)
  const userId = user?.clientId

  const flatCalendarsForMenu = useMemo(
    () => [
      ...calendarsForMenu.programSchoolCalendars,
      ...calendarsForMenu.programStaffCalendars,
    ],
    [calendarsForMenu],
  )

  const { msEvents, onChange, onDelete, addCalendarIdsToFetch, loading } =
    useEventActions([], dateRange)

  const getCalendarById = useGetCalendarById(msEvents, flatCalendarsForMenu)

  return (
    <Stack width={1} height={1} px={3} pt={8}>
      {loading && <FetchEventSnackbar />}
      <Stack flex={1} overflow="auto">
        <MsCalendar
          events={msEvents}
          calendars={buildMsCalendarGroupingsForProgramOwner(
            calendarsForMenu,
            programs,
          )}
          getCalendar={getCalendarById}
          onEventChange={onChange}
          supportCreateEventTypes={["live-session", "regular"]}
          userId={userId ?? ""}
          onDeleteEvent={onDelete}
          showDraftEvents
          canJoinLiveSessions={false}
          onSelectedCalendarChange={(calendarIds: string[]) => {
            const calendarIdsExcludeDraft = calendarIds.filter(
              (c) => !c.includes("draft"),
            )
            addCalendarIdsToFetch(calendarIdsExcludeDraft)
          }}
        />
      </Stack>
    </Stack>
  )
}

export default CalendarContainer
